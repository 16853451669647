import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import ShopContext from '../context/ShopContext'
import Swal from 'sweetalert2'
import { navigate } from "@reach/router"  
import Slider from "react-slick"
import "../slick/slick.css"
import "../slick/slick-theme.css"
import SEO from '../components/seo'

export const query = graphql`
query {
    gijoeio {
        publicCollections {
          collection(alias: "havard", type: "for-sale") {
            figures {
              brokenParts
              condition {
                name
                id
              }
              figure {
                id
                imageUrlRaw
                name
                slug
                tags
                type
                version
                year
              }
              id
              images {
                id
                location
              }
              joints
              missingaccessory {
                name
                id
              }
              note
              oring
              ownedaccessory {
                id
                name
              }
              paint
              price
            }
          }
        }
      }
    
}
`

const addToCart = (context, figure) => {

    let convertedFigure = {
        accessories: figure.ownedaccessory.map(accessory => accessory.name),
        id: figure.id,
        image: figure.images.length === 0 ? `https://cms-aks.gijoe.io${figure.figure.imageUrlRaw}` : `https://gijoe-io.imgix.net/${figure.images[0]?.location}?w=400`,
        isComplete: figure.missingaccessory.length === 0,
        name: figure.figure.name,
        price: figure.price,
        slug: {
            current: `${figure.figure.slug}-v${figure.figure.version}-${figure.figure.year}`
        },
        version: figure.figure.version,
        year: figure.figure.year,
        type: "gijoeio"

    }


    context.addProductToCart(convertedFigure)
    Swal.fire({
        title: 'Produktet er lagt i handlekurven',
        icon: 'success',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Gå til handlekurv',
        cancelButtonText: 'Fortsett å handle',
        
      }).then((result) => {
        if (result.isConfirmed) {
            navigate(`/handlekurv`)

        }
    })
  
}


const GIJoeIOProductTemplate = ({data, pageContext}) => {
    const forSalePost = data.gijoeio.publicCollections.collection.figures.filter(x => x.id === pageContext.id)[0]
    const {figure} = forSalePost

    const slickSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    }
      
   

    return (<>
        <Layout>
            <SEO title={`${figure.name} ${figure.year}`} />
            <ShopContext.Consumer>
                {context => 
                <div className="container">
                    <div className="flex p-5 flex-col-reverse md:flex-row md:p-10 lg:p:20 xl:p-40">
                        <div className="w-full mt-10 md:mt-0 md:w-7/12">
                            <h1>{figure.name}</h1>
                            <p>Versjon: {figure.version}</p>
                            <p>År: {figure.year}</p>
                            <p>Pris: kr {forSalePost.price}</p>

                            <div className="mt-10 mr-5">
                                {figure.notes && (
                                    <p>{figure.notes}</p>
                                )}
                                {figure.accessory?.length > 0 && (
                                    <div className="mt-5">
                                        <h3 className="font-semibold">Utstyr som følger med:</h3>
                                        {figure.accessories.map(accessory => (
                                            <p>- {accessory.name}</p>
                                        ))}
                                    </div>
                                )}
                            </div>
                            
                            <div className="mt-10 items-center flex justify-center ">
                            <button 
                                onClick={() => addToCart(context, forSalePost)}
                                className="focus:outline-none focus:bg-green-700 bg-green-500 hover:bg-green-700 text-white text-sm px-4 py-2 border rounded-full w-full md:w-3/6 xl:w-2/6 h-12"
                            >
                                Kjøp
                            </button>
                            </div>
                        </div>
                        <div className="w-full md:w-5/12">
                            {forSalePost.images.length === 0 && (
                                <img src={`https://cms-aks.gijoe.io${figure.imageUrlRaw}`} alt="Bilde av figur"/>
                            )}
                            {forSalePost.images.length > 0 && (
                                <Slider {...slickSettings} >
                                    {forSalePost.images.map((x, id) => (
                                        <img key={id} src={`https://gijoe-io.imgix.net/${x?.location}?w=400`} alt="Bilde av figur"/>
                                ))}
                                </Slider>
                        
                            )}

                        </div>
                    </div>
                </div>
                }
            </ShopContext.Consumer>
        </Layout>
    </>)
}

export default GIJoeIOProductTemplate